
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/_sites/[tenant]/[[...slug]]",
      function () {
        return require("private-next-pages/_sites/[tenant]/[[...slug]].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/_sites/[tenant]/[[...slug]]"])
      });
    }
  