import { getWebPage, handleGetStaticPaths } from "@siterno/siter-web";
import { Page } from "@parselno/ui";
import { GetStaticPaths, GetStaticProps } from "next";
export const EXCLUDED_TENANTS = ["parsel", "tbook-app", "parsel-app"];

export default Page;
export const getStaticProps: GetStaticProps<
  {},
  {
    slug: string[];
    tenant: string;
  }
> = async (context) => {
  const previewMode = context.preview;
  const tenantIdentifier = context.params?.tenant;
  let slugs = context.params?.slug || [""];
  if (!tenantIdentifier) {
    return {
      notFound: true,
    };
  }
  const data = await getWebPage({
    slugs,
    tenantIdentifier,
    previewMode,
  });
  if (!data.page || !data.siteConfiguration) {
    return {
      notFound: true,
    };
  }
  return {
    props: data,
    revalidate: 3600,
  };
};
export const getStaticPaths: GetStaticPaths = handleGetStaticPaths({
  excludedTenants: EXCLUDED_TENANTS,
});
